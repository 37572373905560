.dishCard {
  max-height: 200px;
  color: #ececec;
  text-shadow: -1px -1px 0 rgb(44, 44, 44), 1px -1px 0 rgb(44, 44, 44), -1px 1px 0 rgb(44, 44, 44),
    1px 1px 0 rgb(44, 44, 44);

  padding: 10px;
  overflow: hidden;
  background: rgb(236, 236, 236);
  background: radial-gradient(circle, rgb(214, 214, 214) 0%, rgba(255, 255, 255, 1) 100%);

  border: 1px solid #f0f0f0;
  border-radius: 8px;
  box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
  // @include prefix(border-radius, 8px, webkit ms);
  min-height: 154px;
}

.lodging {
  border-bottom: 2px #44d3f3 solid;
}

.restaurant {
  border-bottom: 2px #4f44f3 solid;
}

.establishment {
  border-bottom: 2px #bf44f3 solid;
}

.cardTitle {
  // height: 50px;
  display: flex;
  align-items: flex-start;

  & h3 {
    font-weight: 600;
    margin: 0;
    line-height: 1;
    color: white;
  }
}

.cardCity {
  height: 20px;
  display: flex;
  align-items: flex-end;

  & h5 {
    font-weight: 600;
    margin: 0;
    line-height: 1;
    color: white;
  }
}

.cardRating {
  margin: 4px 0px 6px 0px;
  & svg {
    -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
  }
}

.cardPrice {
  text-align: right;
  font-weight: bold;
  margin: 0;
  line-height: 1.3;
  & ::before {
    content: "€ ";
  }
}

.cardDescription {
  // height: 100px;
  overflow: hidden;
  font-size: 0.9rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
