// ::-webkit-scrollbar {
//   width: 8px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   -webkit-border-radius: 10px;
//   border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   -webkit-border-radius: 10px;
//   border-radius: 10px;
//   background: rgba(0, 162, 255, 0.8);
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
// }
// ::-webkit-scrollbar-thumb:window-inactive {
//   background: rgba(0, 162, 255, 0.4);
// }

.gray {
  color: #616573;
}

.featured {
  margin-top: 10px;
  padding: 10px;
}
.itemDesc {
  // font-family: "Droid Serif", serif;
  // font-size: 20px;
  padding-right: 10px;
  font-style: italic;
  white-space: nowrap;
  flex-grow: 0;
}

.itemDots {
  min-width: auto;
  border-bottom: 1px dotted #dedede;

  margin-bottom: 6px;
  // text-decoration: underline;
  // text-underline-position: under;

  // border-bottom-color: #dedede;
  // border-bottom-style: double;
}

.itemPrice {
  text-align-last: right;
  white-space: nowrap;
  width: 2%;

  & span::after {
    content: " €";
  }
}

.categoryRow {
  & h2 {
    padding: 0;
    margin: 0;
  }
}

.categoryTitle {
  padding-bottom: 0 !important;
  margin: 0;
}
